import { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { Loading } from './Loading';

export const ActionButton = ({ callback, instanceState, className, label, disabled, ...props }) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let tid;
        if (isLoading) {
            tid = setTimeout(() => {
            setLoading(false);
            }, 2000);
        }

        return () => clearTimeout(tid);
    }, [isLoading]);

    const handleClick = () => {
        setLoading(true);
        callback();
    }

    return (
        <Button
            className={className}
            onClick={handleClick}
            disabled={disabled || isLoading}
            variant="primary"
            {...props}
        >
            {isLoading ? <Loading/> : label}
        </Button>
    )
}
